import { ProductType } from '../types/event';
import { CustomOption } from 'types/Product';
import { slugify, snakify } from 'helpers/strings';
import { HydratedPDPFields, hydrateProductOptions, scrubValues } from './form';
import { OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR, getCorrectedAttributesValues } from './live-preview';
import { PDPFields } from '../hooks/useProductForm';
import { getStrikethroughPrice } from 'helpers/strikethroughPricing';
import { PriceObject } from '../components/ProductDetails/ProductPrice';
import { SKU_BRASS_WOOD_DISPLAY_BOX } from 'component-library/constants/products';
import { UseCaseProperties } from '../hooks/useProductUseCase';

const CARD_QUANTITY = 'card_quantity';
const ENVELOPE = 'envelope';
const ENVELOPE_ADDRESSING = 'envelope_addressing';

//print set options
const PRINT_QUANTITY = 'print_quantity';
const PRINT_SIZE = 'print_size';
const PAPER_TYPE = 'paper_type';
const TILE_SIZE = 'tile_size';

const PRINT_SIZE_OPTION_NAMES = [TILE_SIZE, PRINT_SIZE];

//book options
const PAGE_COUNT_OPTION_NAME = 'pages';
const ALBUM_SIZE = 'album_size';
const BOOK_SIZE = 'book_size';
const HARDCOVER_BOOK_SIZE = 'hardcover_book_size';
const SOFTCOVER_BOOK_SIZE = 'softcover_book_size';
const BOOK_SIZE_SIZE = 'size';
const BOOK_SIZE_OPTION_NAMES = [ALBUM_SIZE, BOOK_SIZE, HARDCOVER_BOOK_SIZE, BOOK_SIZE_SIZE, SOFTCOVER_BOOK_SIZE];
const PRINTING_STYLE = 'printing_style';
const FOIL_PRINTING_OPTION_VALUE = 'foil';

export const buildPermutationSkuFromValues = (
  product: ProductType,
  sku: string,
  values: { [key: string]: string },
  shouldSlugify = false //temporary fix to allow for permutation skus that incorrectly have a '-' in them such as layflat-10x10-none-linen-terracotta-gold-25-lustre
): string => {
  // Permutation SKU is constructed by concatenating:
  // The sku + alphabetically sorted custom options (snake-ified) with depedependentdent options last
  // we need to have the options in the same order as CC & Magento so that we get the correct permutation sku
  const sortedKeys = Object.keys(values).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  sortedKeys.sort((pdpOptionKeyA, pdpOptionKeyB) => {
    const productOptionA = product.attributes.find(
      attr => attr.name.toLocaleLowerCase() === pdpOptionKeyA.toLocaleLowerCase()
    );
    const productOptionB = product.attributes.find(
      attr => attr.name.toLocaleLowerCase() === pdpOptionKeyB.toLocaleLowerCase()
    );

    if (productOptionA.dependsOnOption && !productOptionB.dependsOnOption) {
      return 1;
    } else if (!productOptionA.dependsOnOption && productOptionB.dependsOnOption) {
      return -1;
    }
    return 0;
  });

  const selectedLabels = sortedKeys
    .map(key => values[key])
    .filter(Boolean)
    .map(shouldSlugify ? slugify : snakify);

  if (selectedLabels.length === 0) {
    return undefined;
  }

  return `${sku.replace(/ /g, '-')}-${selectedLabels.join('-')}`.toLowerCase();
};

export const getPossibleProductIds = (product: ProductType, values: HydratedPDPFields): string[] => {
  // @todo: Calculate possible product ids
  return [product.id];
};

const getPriceTier = (product: ProductType, values: HydratedPDPFields): number => {
  const quantity = getQuantity(values);

  const prices = product?.tierPrices
    ?.filter(tierPrice => tierPrice.quantity === quantity)
    .map(tierPrice => tierPrice.price);

  return prices?.length > 0 ? Math.min(...prices) : 0;
};

export const getProductPrice = (product: ProductType, values: HydratedPDPFields, cleanValues: PDPFields): number => {
  const priceTier = getPriceTier(product, values);

  if (priceTier) {
    return priceTier;
  }

  if (cleanValues.giftcard_amount) {
    return parseFloat(cleanValues.giftcard_amount);
  } else if (product.giftcardAmounts?.length > 0) {
    return parseFloat(product.giftcardAmounts?.sort((a, b) => Number(a.value) - Number(b.value))[0].value);
  }

  const defaultPrice = product.lowestPrice ? product.lowestPrice : product.price;

  //this product is weird and acts as both a simple product and a variable price per print product
  if (isBrassAndWoodDisplayBoxWithPrints(product, values)) {
    return defaultPrice + calculatePriceForPrintSetProduct(product, values);
  } else if (isPrintSetWithPerPrintPricing(product)) {
    return calculatePriceForPrintSetProduct(product, values) || defaultPrice;
  }

  return defaultPrice;
};

export const getQuantity = (values: HydratedPDPFields): number => {
  const cardQuantity = values[CARD_QUANTITY];

  return cardQuantity ? parseFloat((/^([0-9]*)/.exec(cardQuantity.label) || ['0', '0'])[0]) : 1;
};

export const getEnvelopePrice = (_: ProductType, values: HydratedPDPFields): number =>
  (values[ENVELOPE]?.price || 0) + (values[ENVELOPE_ADDRESSING]?.price || 0);

const getProductHoverPrice = (values: HydratedPDPFields): number =>
  Object.values(values)
    .filter(option => !!option?.hoverPrice)
    .map(option => (/^.*\\$([0-9\\.]*).*$/.exec(option?.hoverPrice || '') || ['0', '0'])[0])
    .filter(Boolean)
    .map(parseFloat)
    .reduce((totalPriceAdd, hoverPrice) => totalPriceAdd + hoverPrice, 0);

const getBasicProductPriceAdd = (values: HydratedPDPFields): number =>
  Object.values(values)
    .filter(option => !!option?.price)
    .reduce((totalPriceAdd, option) => totalPriceAdd + option?.price, 0);

const getLowestPageCountOptionValue = (product: ProductType): number => {
  const pagesCustomOption = product.customOptions.find(option => option.title === PAGE_COUNT_OPTION_NAME);

  if (!pagesCustomOption) {
    return 0;
  }

  let lowestPageCount = 0;
  pagesCustomOption.values.map(value => {
    const pageCountValue = parseInt(value.title);
    if (pageCountValue < lowestPageCount || lowestPageCount === 0) {
      lowestPageCount = pageCountValue;
    }
  });

  return lowestPageCount;
};

const isBookSizeOption = optionName => BOOK_SIZE_OPTION_NAMES.includes(optionName);

const isPrintSizeOption = optionName => PRINT_SIZE_OPTION_NAMES.includes(optionName);

const isPrintQuantitySizeOption = optionName => optionName === PRINT_QUANTITY;

const isPrintingStyleSizeOption = optionName => optionName === PRINTING_STYLE;

const isBrassAndWoodDisplayBoxWithPrints = (product: ProductType, values: HydratedPDPFields): boolean => {
  const hasPrints = !!Number(values?.has_prints?.title);
  const isBWDB = product.sku === SKU_BRASS_WOOD_DISPLAY_BOX;
  return hasPrints && isBWDB;
};

const isPrintSetWithPerPrintPricing = (product: ProductType): boolean => {
  if (!product.attributes || product.sku === SKU_BRASS_WOOD_DISPLAY_BOX) {
    return false;
  }

  const customOptions = Object.values(product.attributes);

  if (!customOptions.length) {
    return false;
  }

  let hasSizeOption = false;
  let hasPrintQuantityOption = false;

  customOptions.forEach(option => {
    if (isPrintSizeOption(option.id)) {
      hasSizeOption = true;
    }
    if (option.id === PRINT_QUANTITY) {
      hasPrintQuantityOption = true;
    }
  });

  return product.printPricing && hasPrintQuantityOption && hasSizeOption;
};

//here we are calculating the price for print sets as their price is a table of print_size x print_quantity
//that is on the product's additional_data field in magento
const calculatePriceForPrintSetProduct = (product: ProductType, values: HydratedPDPFields): number => {
  const printSizeOptionName = Object.keys(values).find(value => isPrintSizeOption(value));
  const printQuantityOptionName = Object.keys(values).find(value => isPrintQuantitySizeOption(value));

  if (!printSizeOptionName || !printQuantityOptionName) {
    return 0;
  }

  const printSizeOptionValue = values[printSizeOptionName]?.title;
  const printQuantityOptionValue = values[printQuantityOptionName]?.title;
  const pricePerPrintMap = product.printPricing ? product.printPricing[printSizeOptionName] : {};
  const paperTypeOptionValue = values[PAPER_TYPE]?.title;

  if (!Object.keys(pricePerPrintMap).length) {
    return 0;
  }

  //this ensures that if we search for 5x5 we do not accidentally pick up 3.5x5
  const printSizeRegex = new RegExp(`(?<=^|,)(${printSizeOptionValue})(?=$|,)`);
  const paperTypeRegex = new RegExp(`(?<=^|,)(${paperTypeOptionValue})(?=$|,)`);

  let pricePerPrintForSize = null;
  Object.keys(pricePerPrintMap).forEach(printSizes => {
    if (printSizeRegex.exec(printSizes)) {
      if (pricePerPrintMap[printSizes][printQuantityOptionValue]) {
        pricePerPrintForSize = pricePerPrintMap[printSizes][printQuantityOptionValue];
      }

      //some print sets have per print pricing dependent on the paper_type
      else if (pricePerPrintMap[printSizes]?.[PAPER_TYPE]) {
        Object.keys(pricePerPrintMap[printSizes][PAPER_TYPE]).forEach(paperTypes => {
          if (paperTypeRegex.exec(paperTypes)) {
            pricePerPrintForSize = pricePerPrintMap[printSizes][PAPER_TYPE][paperTypes][printQuantityOptionValue];
          }
        });
      }
    }
  });

  if (!pricePerPrintForSize) {
    return 0;
  }

  return (pricePerPrintForSize || 0) * parseInt(printQuantityOptionValue);
};

const getPricePerPageForBookProduct = (product: ProductType, values: HydratedPDPFields): number => {
  const bookSizeOptionName = Object.keys(values).find(value => isBookSizeOption(value));
  if (!bookSizeOptionName) {
    return 0;
  }

  const bookSizeOptionValue = values[bookSizeOptionName]?.title;
  const additionalPagePriceMap = product.pricingDetailsHtml ? product.pricingDetailsHtml[bookSizeOptionName] : {};

  return additionalPagePriceMap?.[bookSizeOptionValue] || 0;
};

const calculateAdditonalPagePrice = (product: ProductType, values: HydratedPDPFields): number => {
  const pricePerPage = getPricePerPageForBookProduct(product, values);

  if (pricePerPage === 0) {
    return 0;
  }

  const includedPages = getLowestPageCountOptionValue(product);
  const chosenPages = parseInt(values.pages?.title) || includedPages;

  if (includedPages === chosenPages) {
    return 0;
  }

  const additionalPages = chosenPages - includedPages;
  const additionalPrice = additionalPages * pricePerPage;
  return Math.max(0, additionalPrice);
};

export const maybeAddFoilPrintingUpcharge = (product: ProductType, values: HydratedPDPFields): number => {
  if (product.additionalFoilPrice) {
    const printingStyleOptionName = Object.keys(values).find(value => isPrintingStyleSizeOption(value));
    const printingStyleOptionValue = values[printingStyleOptionName]?.title;

    if (printingStyleOptionValue !== FOIL_PRINTING_OPTION_VALUE) {
      return 0;
    }

    const bookSizeOptionName = Object.keys(values).find(value => isBookSizeOption(value));
    const bookSizeOptionValue = values[bookSizeOptionName]?.title;

    return product.additionalFoilPrice?.[bookSizeOptionName]?.[bookSizeOptionValue] ?? 0;
  }

  return 0;
};

export const getProductPriceAdd = (product: ProductType, values: HydratedPDPFields): number => {
  const basicPriceAdd = getBasicProductPriceAdd(values);

  if (product.tierPrices.length) {
    return basicPriceAdd + getProductHoverPrice(values) - getEnvelopePrice(product, values);
  }

  if (isBook(product)) {
    return basicPriceAdd + calculateAdditonalPagePrice(product, values) + maybeAddFoilPrintingUpcharge(product, values);
  }

  return basicPriceAdd;
};

export const isCard = (product: ProductType): boolean => {
  const cardTypes = ['Card', 'STDCards', 'TYCards'];
  return cardTypes.includes(product.customProductType) || product.reportingProductCategory === 'cards';
};

export const isBook = (product: ProductType): boolean => {
  return product.reportingProductCategory === 'books';
};

export const getProductPriceForOptions = (product: ProductType, values: PDPFields, prefix?: string): PriceObject => {
  const cleanValues = scrubValues(prefix, values);
  const hydratedValues = hydrateProductOptions(product.attributes, cleanValues);

  const envelopePrice = getEnvelopePrice(product, hydratedValues);
  const _isCard = isCard(product);
  const quantity = getQuantity(hydratedValues);

  const productPrice = getProductPrice(product, hydratedValues, cleanValues);
  const productPriceAdd = getProductPriceAdd(product, hydratedValues);

  //If product is a card and the quantity is 1, that means the user hasn't selected a quantity yet. In
  //That case, the displayed prices should assume the lowest quantity like we do in the structured data.
  let productPriceForTotal = productPrice;
  let quantityForTotal = quantity;
  if (_isCard && quantity === 1 && product?.tierPrices?.length > 0) {
    const lowestQuantityTierPrice = product.tierPrices[0];
    productPriceForTotal = lowestQuantityTierPrice.price;
    quantityForTotal = lowestQuantityTierPrice.quantity;
  }

  const productPriceTotal = (productPriceForTotal + productPriceAdd + envelopePrice) * quantityForTotal;
  const strikethroughPrice = getStrikethroughPrice(product.strikethroughValues, productPriceTotal, product.sku);

  const priceObject: PriceObject = {
    productPrice,
    basePrice: 0,
    productPriceAdd,
    envelopePrice,
    quantity,
    specialPrice: 0,
    isCard: _isCard,
    isConfigurable: false,
    isStandalone: false,
    catalogPriceRules: product.catalogPriceRules || [],
    possibleProductIds: getPossibleProductIds(product, hydratedValues),
    strikethroughPrice,
    promoCode: product.strikethroughValues?.promoCode || null,
    productCategory: product.reportingProductCategory,
    productPriceTotal
  };

  return priceObject;
};

export const getProductLivePreviewUrl = (sku: string, livePreviewAttributes: string[], values: PDPFields): string => {
  if (livePreviewAttributes.length === 0 || !values) {
    return '';
  }

  const correctedValues = getCorrectedAttributesValues(livePreviewAttributes, values);
  const correctedAttributes: (keyof PDPFields)[] = Object.keys(correctedValues);

  // `filteredOptionalAttributes`: if we have fields in the format of `|field2` or `field1|`, then we need to filter
  // The attribute to count only those that matter (only the ones that exists in the correctedAttributes object).
  // This won't affect the actual bahavior for attributes with the form of `field1|field2`.
  const filteredOptionalAttributes = livePreviewAttributes
    .filter(attribute => attribute.includes(OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR))
    .filter(attribute => {
      const [field1, field2] = attribute.split(OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR);
      const nonOptionField = field1 || field2 || '';

      return (!field1 || !field2) && !correctedAttributes.includes(nonOptionField);
    });

  if (correctedAttributes.length !== livePreviewAttributes.length - filteredOptionalAttributes.length) {
    return '';
  }

  const attributesString = correctedAttributes.map(attr => correctedValues[attr].replace(/\s/g, '+')).join('-');

  return `${process.env.GATSBY_LIVE_PREVIEW_URL}/${sku}-${attributesString}.jpg`;
};

export const getUseCaseAttributes = (
  productName: string,
  values: PDPFields,
  useCaseFields: CustomOption[]
): UseCaseProperties => {
  const returnAttributes = {
    title: productName,
    useCaseImage: null
  };
  useCaseFields.forEach(field => {
    if (values[field.title]) {
      const foundValue = field.values.find(value => {
        return value.title === values[field.title];
      });
      if (foundValue) {
        const title = foundValue.use_case_product_title !== '' ? foundValue.use_case_product_title : productName;
        returnAttributes.title = title;
        if (foundValue.use_case_default_image_url) {
          returnAttributes.useCaseImage = {
            caption: title,
            full: foundValue.use_case_default_image_url,
            img: foundValue.use_case_default_image_url,
            isMain: true,
            position: '0',
            thumb: foundValue.use_case_default_image_url,
            type: 'image',
            url: foundValue.use_case_default_image_url,
            videoUrl: null
          };
        }
      }
    }
  });
  return returnAttributes;
};
